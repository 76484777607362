import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { httpGetBffAvdEntraUserIsSynced, httpGetBffAvdGetAvdLoginUrl, httpGetBffAvdUserHasRole } from '@zvoove-sso/api';
import { LoginContextService, RedirectService } from '@zvoove-sso/shared';
import { combineLatest, map, of, switchMap } from 'rxjs';

export const dependencies = { location: window.location };

export const avdNotSyncedOrNoAvdRoleGuard = (): CanActivateFn => () => {
  const http = inject(HttpClient);
  const auth = inject(LoginContextService);
  const redirect = inject(RedirectService);

  const userId = auth.userId();
  if (!userId) {
    return false;
  }

  return combineLatest([httpGetBffAvdEntraUserIsSynced(http, { query: { userId: userId } }), httpGetBffAvdUserHasRole(http)]).pipe(
    switchMap(([synced, hasRole]) => {
      if (synced && hasRole) {
        console.log(`ARGH ${synced} ${hasRole}`);
        return httpGetBffAvdGetAvdLoginUrl(http).pipe(
          map((url) => {
            console.log('GAH');
            redirect.redirect(url);
            return false;
          })
        );
      }
      return of(true);
    })
  );
};
