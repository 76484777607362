import { Routes } from '@angular/router';
import { alreadyLoggedInGuard, forNonZvooveUsersOnlyGuard } from '@zvoove-sso/shared';
import { avdNotSyncedOrNoAvdRoleGuard } from './shared/guard/avd-not-synced-or-no-role.guard';

const getPageTitle = (localizedPart: string) => {
  return localizedPart + ' | zvoove Identity';
};

export const appRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./app/shell/shell.component').then((c) => c.ShellComponent),
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'logged-out',
        title: () => getPageTitle($localize`:@@title.goodbye:Auf Wiedersehen`),
        loadComponent: () => import('./app/logged-out/logged-out.page').then((c) => c.LoggedOutPage),
      },
      {
        path: 'login',
        canActivate: [alreadyLoggedInGuard],
        title: () => getPageTitle($localize`:@@title.welcome:Willkommen`),
        loadComponent: () => import('./app/login/login.page').then((c) => c.LoginPage),
      },
      {
        path: 'login-password',
        canActivate: [alreadyLoggedInGuard],
        title: () => getPageTitle($localize`:@@title.welcome:Willkommen`),
        loadComponent: () => import('./app/login-password/login-password.page').then((c) => c.LoginPasswordPage),
      },
      {
        path: 'login-two-step',
        canActivate: [alreadyLoggedInGuard],
        title: () => getPageTitle($localize`:@@title.welcome:Willkommen`),
        loadComponent: () => import('./app/login-two-step/login-two-step.page').then((c) => c.LoginTwoStepPage),
      },
      {
        path: 'logout',
        title: () => getPageTitle($localize`:@@title.goodbye:Auf Wiedersehen`),
        loadComponent: () => import('./app/logout/logout.page').then((c) => c.LogoutPage),
      },
      {
        path: 'avd',
        canActivate: [avdNotSyncedOrNoAvdRoleGuard()],
        title: () => getPageTitle($localize`:@@title.expertMode:Expert Mode`),
        loadComponent: () => import('./app/avd/sync/avd-sync.page').then((c) => c.AvdSyncPage),
      },
      {
        path: 'account',
        children: [
          {
            path: 'forgot-password',
            canActivate: [alreadyLoggedInGuard],
            title: () => getPageTitle($localize`:@@general.forgotPassword:Passwort vergessen`),
            loadComponent: () => import('./app/account/forgot-password/forgot-password.page').then((c) => c.ForgotPasswordPage),
          },
          {
            path: 'forgot-password-email-sent',
            canActivate: [alreadyLoggedInGuard],
            title: () => getPageTitle($localize`:@@general.forgotPassword:Passwort vergessen`),
            loadComponent: () =>
              import('./app/account/forgot-password-email-sent/forgot-password-email-sent.page').then((c) => c.ForgotPasswordEmailSentPage),
          },
          {
            path: 'register',
            title: () => getPageTitle($localize`:@@general.register:Registrieren`),
            loadComponent: () => import('./app/account/register/register.page').then((c) => c.RegisterPage),
          },
          {
            path: 'registration-complete',
            title: () => getPageTitle($localize`:@@register.completed:Registrierung abgeschlossen`),
            loadComponent: () =>
              import('./app/account/registration-complete/registration-complete.page').then((c) => c.RegistrationCompletePage),
          },
          {
            path: 'reset-password',
            canActivate: [alreadyLoggedInGuard],
            title: () => getPageTitle($localize`:@@general.resetPassword:Passwort zurücksetzen`),
            loadComponent: () => import('./app/account/reset-password/reset-password.page').then((c) => c.ResetPasswordPage),
          },
          {
            path: 'reset-password-confirmation',
            canActivate: [alreadyLoggedInGuard],
            title: () => getPageTitle($localize`:@@general.resetPassword:Passwort zurücksetzen`),
            loadComponent: () =>
              import('./app/account/reset-password-confirmation/reset-password-confirmation.page').then(
                (c) => c.ResetPasswordConfirmationPage
              ),
          },
          {
            path: 'update',
            title: () => getPageTitle($localize`:@@title.userProfile:Benutzerprofil`),
            canActivate: [forNonZvooveUsersOnlyGuard],
            loadComponent: () => import('./app/account/update/update.page').then((c) => c.UpdatePage),
          },
        ],
      },
      {
        path: 'healthz',
        loadComponent: () => import('./app/healthz/healthz.page').then((m) => m.HealthzPage),
      },
      {
        path: 'debug',
        loadComponent: () => import('./app/debug/debug.page').then((m) => m.DebugPage),
      },
      {
        path: '**',
        title: () => getPageTitle($localize`:@@error.notFoundOhNo:Oh nein!`),
        loadComponent: () => import('./app/page-not-found/page-not-found.page').then((m) => m.PageNotFoundPage),
      },
    ],
  },
];
