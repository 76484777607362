/// <reference types="@angular/localize" />

import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler, enableProdMode, provideZoneChangeDetection } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import {
  FormService,
  GlobalErrorHandler,
  ZvExceptionMessageExtractorExtension,
  bootstrapAngular,
  ssoHttpInterceptor,
  statusCodeInterceptor,
  versionCheckInterceptor,
} from '@zvoove-sso/shared';
import { ZvExceptionMessageExtractor } from '@zvoove/components/core';
import { ZvFormService } from '@zvoove/components/form-base';
import { DefaultZvSelectService, ZvSelectService } from '@zvoove/components/select';
import { from } from 'rxjs';
import { AppComponent } from './app/app.component';
import { i18nModule } from './app/i18n/i18n.module';
import { environment } from './environments/environment';
import { appRoutes } from './routes';

if (environment.production) {
  enableProdMode();
}

bootstrapAngular({
  configUrl: environment.backendBaseUrl,
  prod: environment.production,
  bootstrapFunc: () => bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err)),
});

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    i18nModule.setLocale(),
    i18nModule.setLocaleId(),
    { provide: ZvFormService, useClass: FormService },
    { provide: ZvSelectService, useClass: DefaultZvSelectService },
    provideRouter(appRoutes, withComponentInputBinding()),
    provideHttpClient(
      withInterceptors([
        ssoHttpInterceptor,
        versionCheckInterceptor({
          appVersion: document.querySelector('meta[name="version"]')?.getAttribute('content') ?? '',
          getNewestAppVersion: () =>
            from(
              fetch('/ui/index.html')
                .then((res) => res.text())
                .then((html) => {
                  const parser = new DOMParser();
                  const htmlDoc = parser.parseFromString(html, 'text/html');
                  return htmlDoc.querySelector('meta[name="version"]')?.getAttribute('content') ?? '';
                })
            ),
          throttleMs: 60_000,
        }),
        statusCodeInterceptor({ statusCodesToLog: [403] }),
      ])
    ),
    provideAnimations(),
    { provide: ZvExceptionMessageExtractor, useClass: ZvExceptionMessageExtractorExtension },
  ],
};
